<template>
  <hot-table
    ref="hottable"
    :license-key="licenseKey"
    :data="data"
    :col-headers="colHeaders"
    :columns="columns"
    :settings="settings"
    :after-change="onChange"
  />
</template>

<script>
import { HotTable } from '@handsontable/vue'

export default {
  name: 'RSpreadsheet',
  components: {
    HotTable
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    colHeaders: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    settings: {
      type: Object,
      default: () => {}
    },
    onChange: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      licenseKey: '40f93-b5f7f-7ca92-2422a-6335a'
    }
  },
  methods: {
    clear () {
      this.$refs.hottable.hotInstance.clear()
    }
  }
}
</script>
