<template>
  <RSpinnerOverlay
    :loading="loading"
    :opacity="0.8"
    :z-index="1028"
    :is-full-page="true"
  >
    <CRow class="mb-4">
      <CCol>
        <h2>Invoice Processing Tool</h2>
        <span>
          Tool for processing incoming invoice payments.
          To record the payment, enter the invoice number and the amount paid, then hit send to Zoho.
        </span>
      </CCol>
    </CRow>
    <CRow class="mb-4">
      <CCol>
        <h6>Date Paid:</h6>
        <v-date-picker v-model="date" class="date-picker" />
      </CCol>
    </CRow>
    <CRow class="mb-4">
      <CCol>
        <h6>Invoice numbers and amounts:</h6>
        <RSpreadsheet
          ref="spreadsheet"
          :data="tableData"
          :col-headers="colHeaders"
          :columns="columns"
          :settings="spreadsheetSettings"
          :on-change="handleSpreadsheetChange"
        />
        <CButton
          class="mt-3"
          color="info"
          shape="pill"
          @click="sendToZoho"
        >
          Send to Zoho
        </CButton>
      </CCol>
    </CRow>
    <CAlert v-if="failures.length" color="danger" close-button>
      <h6>Uh Oh!</h6>
      <p>The following {{ failures.length }} invoices have failed:</p>
      <ul>
        <li v-for="(failure, index) in failures" :key="index">
          INV-{{ failure.invoice_number }}: {{ failure.message }}
        </li>
      </ul>
    </CAlert>
    <CAlert v-if="successes.length" color="success" close-button>
      <h6>Success!</h6>
      <p>{{ successes.length }} invoice payments have been recorded in Zoho:</p>
      <ul>
        <li v-for="(success, index) in successes" :key="index">
          INV-{{ success.invoice_number }}
        </li>
      </ul>
    </CAlert>
  </RSpinnerOverlay>
</template>

<script>
import { RSpinnerOverlay } from '@/components/spinners'
import { RSpreadsheet } from '@/components/spreadsheets'
import { InvoicesAPI } from '@/services/api/resources'
import { formatDate } from '@/utils'

export default {
  name: 'InvoiceProcessing',
  components: { RSpinnerOverlay, RSpreadsheet },
  data () {
    return {
      loading: false,
      date: new Date(),
      tableData: [],
      colHeaders: ['Invoice Number', 'Amount'],
      columns: [
        {
          data: 'invoice_number',
          type: 'text'
        },
        {
          data: 'amount_applied',
          type: 'numeric'
        }
      ],
      spreadsheetSettings: {
        minRows: 20,
        minSpareRows: 1,
        width: '400',
        height: '430',
        stretchH: 'all',
        manualColumnResize: true,
        contextMenu: ['row_above', 'row_below', 'remove_row', 'undo', 'redo', 'cut', 'copy']
      },
      successes: [],
      failures: []
    }
  },
  methods: {
    /**
     * Calls API to submit invoices to Zoho.
     */
    async sendToZoho () {
      this.loading = true
      this.successes = []
      this.failures = []
      const invoices = this.tableData.filter(value => value.amount_applied && value.invoice_number)

      if (!invoices.length || !this.isValid(invoices)) {
        this.$store.commit('notification/show', {
          message: 'Please enter correct values.',
          type: 'warning'
        })
        this.loading = false
        return
      }
      const accounts = await InvoicesAPI.bankAccounts.list()
      const accountId = accounts && Object.keys(accounts.data).find(key => accounts.data[key].name === 'TD USD')

      if (!accountId) {
        this.$store.commit('notification/show', {
          message: 'Account not found.',
          type: 'warning'
        })
        this.loading = false
        return
      }

      const date = formatDate(this.date, 'yyyy-MM-dd')

      const data = invoices.map(invoice => {
        return {
          date: date,
          amount: invoice.amount_applied.toString(),
          account_id: accountId,
          invoices: [{
            invoice_number: 'INV-' + invoice.invoice_number,
            amount_applied: invoice.amount_applied.toString()
          }]
        }
      })

      const response = await InvoicesAPI.payments.create({ data })
      if (response) {
        for (let i = 0; i < response.data.length; i++) {
          const result = response.data[i]
          if (result.ok) {
            this.successes.push(invoices[i])
          } else {
            this.failures.push({ ...invoices[i], message: result.failure })
          }
        }
        this.$refs.spreadsheet.clear()
      }
      this.loading = false
    },
    /**
     * Validation for invoices.
     *
     * @param {Array} invoices
     * @returns {Boolean}
     */
    isValid (invoices) {
      for (const invoice of invoices) {
        if (isNaN(invoice.amount_applied) || !this.hasValidDecimals(invoice.amount_applied)) {
          return false
        }
      }
      return true
    },
    /**
     * Returns false if number has more than two decimal places
     *
     * @params {Number} amount
     * @returns {Boolean}
     */
    hasValidDecimals (amount) {
      const amountStr = amount.toString()
      if (amountStr.includes('.')) {
        return amountStr.split('.')[1].length <= 2
      }
      return true
    },
    /**
     * Handsontable afterChange handler.
     *
     * @param {Array} changes - 2D array containing info about each of the edited cells
     * @param {String} source - identifies source of hook call
     */
    handleSpreadsheetChange (changes, source) {
      if (source === 'loadData') {
        return
      }
      for (const [row, prop, oldValue, newValue] of changes) {
        if (oldValue === newValue) {
          continue
        }
        if (this.tableData.length <= row) {
          this.tableData.push({ [prop]: newValue })
        } else {
          this.tableData[row][prop] = newValue
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.date-picker {
  ::v-deep input {
    width: auto;
  }
}
::v-deep .vc-popover-content-wrapper {
  z-index: 161; // need to be higher than the spreadsheet
}
</style>
